<template>
  <div id="studentExamResult">
    <!-- breadCrumb -->
    <v-breadcrumbs :items="breadcrumbList" divider="/">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <!-- breadCrumb -->
    <v-row>
      <v-col cols="10">
        <div class="title">
          <h1>{{ ExamData.grade_name }}</h1>
        </div>
        <div class="title">
          <h2 class="main-color">
            {{ ExamData.exam_name }}
          </h2>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" offset-md="2" class="info-list">
        <ul class="inline">
          <li>
            <strong> {{ $t("Date From") }}: </strong
            >{{ ExamData.exam_date_from }}
          </li>
          <li>
            <strong> {{ $t("Date To") }}: </strong>{{ ExamData.exam_date_to }}
          </li>
          <li>
            <strong> {{ $t("Exam Mark") }}: </strong>{{ ExamData.exam_mark }}
          </li>
        </ul>
      </v-col>

      <v-col cols="12" md="12" class="hr-div">
        <hr />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" class="table-grid">
        <v-form ref="form" v-model="valid">
          <v-data-table
            :options.sync="options"
            :headers="headers"
            :items="ExamMarks"
            class="elevation-1 level1 ExamMarks"
            :loading="loading"
            item-key="id"
            loading-text="Loading... Please wait"
            hide-default-footer
          >
            <template v-slot:item.actions="{ item }">
              <router-link
                :to="'/ApplicantAnswer/' + examId + '/' + item.applicant_code"
                title="Show Applicant Answers"
              >
                <v-icon>assignment</v-icon>
              </router-link>
            </template>
          </v-data-table>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "StudentsExamResults",
  data() {
    return {
      valid: true,
      snackbar: false,
      color: "",
      text: "",
      className: "",
      ExamData: {},
      ExamMarks: [],
      headers: [
        { text: this.$i18n.t("Applicant Code"), value: "applicant_code" },
        { text: this.$i18n.t("Name"), value: `applicant_name` },
        { text: this.$i18n.t("Mark"), value: "applicant_mark", width: "100px" },
        {
          text: this.$i18n.t("Actions"),
          value: "actions",
          sortable: false,
          width: "6%",
        },
      ],
      loading: false,
      options: {
        itemsPerPage: 100,
      },
      examId: "",
      edit: false,
      requiredRules: {
        required: (v) => !!v || this.$i18n.t("This field is required"),
      },
      // spacesOnly: {
      //   spaces: v =>
      //     /^(.[^ ].)/.test(v) || "This field shouldn't contain spaces only"
      // },

      onlyNumbers: {
        numbers: (v) =>
          (v && /^-?\d*(\.\d+)?$/.test(v)) ||
          this.$i18n.t("This field only accept numbers"),
      },
      greaterThanZer: {
        numbers: (v) =>
          (v && v >= 0) || this.$i18n.t("This field only accept numbers >= 0"),
      },
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  methods: {
    openAddMarks() {
      if (this.ExamData.add && this.addMarksVisible) {
        this.edit = true;
      }
    },
    getExamData() {
      axios
        .get(this.getApiUrl + "/exams/show/" + this.examId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.ExamData = response.data.data;
          if (!this.ExamData.online_exam) {
            this.headers = this.headers.filter(function (row) {
              return row.value != "actions";
            });
          }
          this.openAddMarks();
        });
    },
    getDataFromApi() {
      //   let sort_type = "";
      //   let column = "";
      //   if (this.options.sortBy.length > 0) {
      //     column = this.options.sortBy[0];
      //     sort_type = this.options.sortDesc[0] ? "DESC" : "ASC";
      //   }
      axios
        .get(this.getApiUrl + "/getAddminssionExamMark/" + this.examId, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.ExamData = response.data.data.exam;
          this.ExamMarks = response.data.data.applicants;
        });
    },

    cancel() {
      this.edit = false;
      // call api again to get data from server
      this.getDataFromApi();
    },
    markRuleCheck(mark, total) {
      total = parseFloat(total);
      mark = parseFloat(mark);
      return mark > total ? "Mark shall not exceed the total mark" : true;
    },
    // checkMarkIsNumber(mark) {
    //   mark = parseFloat(mark);
    //   console.log(isNaN(mark));
    //   isNaN(mark) ? "This field only except number" : true;
    // }
  },
  mounted() {
    this.examId = this.$router.currentRoute.params.examId;
    // this.getExamData();
    this.getDataFromApi();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/_forms.scss";
.title h2 {
  margin-bottom: 0 !important;
  font-size: 1.5rem;
}
.info-list {
  padding-top: 0;
  padding-bottom: 0;
  li {
    display: inline-block;
    width: 33%;
    font-size: 13px;
    padding-right: 2px;
  }
}

@media (max-width: 600px) {
  .info-list {
    li {
      font-size: 10px;
    }
  }
}

.icons-div,
.hr-div {
  padding-top: 0;
  padding-bottom: 0;

  hr {
    margin-bottom: 0 !important;
    margin-top: 0.5rem !important;
  }
}

.table-grid {
  padding-top: 0 !important;
}

button.ui-btn.submit:disabled {
  cursor: not-allowed;
}
</style>
